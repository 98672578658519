<template>
    <div class="create-manage">
        <el-page-header @back="goBack" content="添加管理员">
        </el-page-header>
        <div class="form">
            <el-form :model="form" :rules="rules" ref="ruleForm" @keyup.enter.native="tirggerSubmit">
                <el-form-item label="邮箱" prop="email">
                    <el-input v-model="form.email"></el-input>
                </el-form-item>
                <el-form-item label="用户名称" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="form.username" ></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="form.password"  placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="password_confirmation" >
                    <el-input v-model="form.password_confirmation" placeholder="请输入跟密码一致"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :disabled="submit"  :loading="submit" ref="submit" @click="onSubmit" style="margin-top: 1rem">立即创建</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {storeManager} from "../../api/manage";
    import { EventBus } from "../../utils/event-bus";

    export default {
        name: "Managecreate",
        data(){
            const validatePass = (rule, value, callback) => {
                if (value === '' || value === null) {
                    callback(new Error('请输入确认密码'));
                } else {
                    setTimeout(() => {
                        if (this.form.password_confirmation !== '') {
                            this.$refs.ruleForm.validateField('password');
                        }
                        callback();
                    }, 1000);
                }
            };
            const validatePassConfirmed = (rule, value, callback) => {
                if (value === '' || value === null) {
                    callback(new Error('请输入密码'));
                }
                if (value === '' || value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.form.password) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return{
                submit: false,
                form:{
                    email: null,
                    name: null,
                    username: null,
                    password: null,
                    'password_confirmation': null
                },
                rules:{
                    email: [
                        { required: true, message: '请输入用户邮箱', trigger: 'blur' },
                    ],
                    name:[
                        {required: true, message:'请输入名称', trigger: 'blur'}
                    ],
                    username: [
                        {required: true, message:'请输入用户名', trigger: 'blur'}
                    ],
                    password: [
                        { validator: validatePass, trigger: 'blur' }
                    ],
                    password_confirmation: [
                        { validator: validatePassConfirmed, trigger: 'change' }
                    ],
                }
            }
        },
        methods:{
             tirggerSubmit(){
               this.$refs['submit'].click()
             },
             onSubmit(){
                    // this.submit = true;
                    this.$refs['ruleForm'].validate(async (valid) => {
                        if (valid) {
                            try {
                                let manage = await storeManager('admin/manage', this.form);
                                if(manage) {
                                    if (manage.msg){
                                        this.$message({
                                            'message' : manage.msg,
                                            'type' : 'success'
                                        })
                                        setTimeout(() => {
                                            EventBus.$emit('push-manage', manage.manage)
                                            this.goBack();
                                        }, 100)
                                    }
                                    this.submit = false;
                                }
                            }catch (e) {
                                //处理错误
                                let msg = '';
                                // eslint-disable-next-line no-unused-vars
                                Object.values(e).map((error,i) => {
                                    msg += `<li style="list-style: outside">${error}</li>`;
                                });
                                this.$message({
                                    type: 'error',
                                    dangerouslyUseHTMLString: true,
                                    message: `${msg}`
                                })
                            }

                        } else {
                            return false;
                        }
                    });
                    // this.goBack()
            },
            goBack(){
                this.$router.push({name:'manageList', query:{ pagesize: this.$route.query.pagesize, curr: this.$route.query.currentPage }});
            }
        }
    }
</script>

<style scoped>
    .create-manage{
        background: #ffffff;
        padding: 15px;
    }
    .form{
        margin-top: 1rem;
    }
</style>